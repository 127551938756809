import gql from "graphql-tag";

export const queryOnSaleItems = gql`
  query OnsaleItems(
    $productId: String!
    $postalCode: String
    $filter: String = ""
    $userType: String
    $currencyCode: String
    $siteId: String!
    $locale: String!
    $fromCount: String
    $toCount: String
    $nextgenDriven: Boolean
    $page: String
    $resultsPerPage: String
  ) {
    onsaleItems(
      productId: $productId
      postalCode: $postalCode
      filter: $filter
      userType: $userType
      currencyCode: $currencyCode
      siteId: $siteId
      locale: $locale
      fromCount: $fromCount
      toCount: $toCount
      nextgenDriven: $nextgenDriven
      page: $page
      resultsPerPage: $resultsPerPage
    ) {
      showViewMore
      adapter
      instockItems {
        skuId
        fullSkuId
        inStockLowQtyMessage
        inventoryMessage
        imageUrl
        qtyAvailable
        inventoryOnHand
        skuIds
        components {
          fullSkuId
          productId
          quantity
        }
        bundleComponents {
          imageUrl
          productId
          fullSkuId
          name
        }
        optionList {
          id
          label
          optionType
          sortPriority
        }
        skuPriceInfo {
          contractPrice
          fullSkuId
          currencySymbol
          listPrice
          salePrice
          memberPrice
          tradePrice
          onSale
          onClearance
          showMemberPrice
        }
      }
    }
  }
`;
