import "cross-fetch/polyfill";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";

import introspectionQueryResultData from "./introspection-result.json";
import { initialAppData, queryApp } from "./queries/app";
import { getReqContext } from "utils/reqContext";
import { getBffOrigin } from "utils/getBffOrigin";
import type { ClientType } from "./client-type";
import { getClientOrigin } from "utils/getClientOrigin";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { uncrunch, CrunchedData } from "graphql-crunch";
import yn from "yn";
import { createHash } from "crypto";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";

export default (): ClientType => {
  const exportObject: any = {};

  const env = globalThis._env;

  const FEATURE_GRAPHQL_CACHE = yn(env?.FEATURE_GRAPHQL_CACHE);
  const FEATURE_GRAPHQL_CRUNCH = yn(env?.FEATURE_GRAPHQL_CRUNCH);
  const FEATURE_GRAPHQL_PERSISTED_QUERIES = yn(
    env?.FEATURE_GRAPHQL_PERSISTED_QUERIES
  );

  const sha256 = async (buffer: any): Promise<string> => {
    const hash = createHash("sha256");
    hash.update(buffer, "utf8");
    return hash.digest("hex");
  };

  const cache = FEATURE_GRAPHQL_CACHE
    ? new InMemoryCache({
        addTypename: false,
        possibleTypes: introspectionQueryResultData.possibleTypes,
        typePolicies: {
          // Fixes KRAK-2827 duplicate product options
          ProductItemAttribute: {
            // if id is null this prevents the cache from overwriting if we ensure that the keyField is unique somehow
            keyFields: o =>
              `ProductItemAttribute:${o.__typename}:${
                o.id
              }:${`${o.value}`.replace(/ /g, "")}}`
          },
          LineItemType: {
            keyFields: o => `LineItemType:${o.lineId}`
          },
          MonogramType: {
            keyFields: o => `MonogramType:${o.styleId}}`
          },
          // Fixes SHOP-1110
          NavigationElement: {
            keyFields: o =>
              `NavigationElement:${o.__typename}:${o.id}:${o.displayName}:${o?.siteId}}`
          },
          SaleNavigationElement: {
            keyFields: o =>
              `SaleNavigationElement:${o.__typename}:${o.id}:${o.displayName}:${o?.siteId}:${o?.filter}}`
          },
          ProductItemOption: {
            keyFields: o =>
              `ProductItemOption:${o.__typename}:${o.id}:${o?.optionType}:${o?.label}`?.replace(
                /\s/gi,
                ""
              )
          },
          ProductFullSkuSwatchIdsArgs: {
            keyFields: o => `ProductFullSkuSwatchIdsArgs:${o?.fullSkuId}}`
          },
          ProductSwatch: {
            keyFields: o => `ProductSwatch:${o?.swatchId}}`
          },
          Product: {
            keyFields: o => `Product:${o?.id}`
          },
          CategoryProduct: {
            keyFields: o => `CategoryProduct:${o?.id}:${o?.displayName}}`
          },
          TopSwatch: {
            keyFields: o => `TopSwatch:${o?.id}:${o?.sale}:${o?.imageRef}`
          },
          SearchResponse: {
            keyFields: o => `SearchResponse:${o?.searchQueryId}}`
          },
          QueryInfiniteScrollArgs: {
            keyFields: o =>
              `QueryInfiniteScrollArgs:${o?.product_id}:${o?.categoryId}`
          },
          SearchRecordProduct: {
            keyFields: o => {
              const fullSkuId = (o?.skuPriceInfo as any)?.fullSkuId?.replace(
                /\s/g,
                ""
              );
              return `SearchRecordProduct:${`${o?.displayName}`?.replace(
                /\s/g,
                ""
              )}:${o?.repositoryId}:${fullSkuId}}`;
            }
          }
        }
      })
    : new InMemoryCache({ dataIdFromObject: () => null });

  let cacheOptions: any = { cache };
  if (!FEATURE_GRAPHQL_CACHE) {
    cacheOptions = {
      ...cacheOptions,
      defaultOptions: {
        query: {
          errorPolicy: "all",
          fetchPolicy: "no-cache"
        },
        watchQuery: {
          errorPolicy: "all",
          fetchPolicy: "no-cache"
        }
      }
    };
  }

  exportObject.cache = cache;

  if (FEATURE_GRAPHQL_CACHE) {
    cache.writeQuery({
      query: queryApp,
      data: initialAppData
    });
  }

  const httpLink = new HttpLink({
    uri: "",
    credentials: "include",
    fetchOptions: {
      method: "GET"
    },
    fetch: (url, options) => {
      const req: any = getReqContext();
      Object.assign(options?.headers!, {
        cookie: req.headers.cookie,
        "Client-Origin": getClientOrigin(),
        "x-client-locale": `${getLanguageFromUrl().raw}-${getCountryFromUrl()}`,
        "x-client-country": getCountryFromUrl()
      });

      const origin =
        env.ENV_ID === "LOCAL"
          ? process.env.REACT_APP_BFF_ORIGIN
          : getBffOrigin(req.headers?.host);

      url = decodeURI((url as string).replace("/graphql", "")).replace(
        /(?:\s)+/g,
        " "
      );

      return fetch(
        `${origin}${env.REACT_APP_BFF_HTTP_LINK_PATH}` + url,
        options
      );
    }
  });

  const mergedLink = FEATURE_GRAPHQL_PERSISTED_QUERIES
    ? createPersistedQueryLink({ sha256 }).concat(httpLink)
    : httpLink;

  exportObject.client = new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([
      new ApolloLink((operation, forward) => {
        return forward(operation).map(response => {
          if (FEATURE_GRAPHQL_CRUNCH && response && response.data) {
            response.data = uncrunch(response.data as CrunchedData) as {
              [key: string]: any;
            };
          }
          return response;
        });
      }),
      mergedLink
    ]),
    name: "rh-estore-server",
    ...cacheOptions
  });

  return exportObject as ClientType;
};
