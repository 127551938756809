import gql from "graphql-tag";
import { GiftRegistry } from "graphql-client/fragments/gift-registry";

export const queryGetGiftRegistryList = gql`
  query GetGiftRegisryList($email: String!, $guestId: String!) {
    getGiftRegistryList(email: $email, guestId: $guestId) {
      ...GiftRegistry
    }
  }
  ${GiftRegistry}
`;
