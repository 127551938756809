import gql from "graphql-tag";
import { CollectionCategoryFields } from "graphql-client/fragments/collection-category-fields";

export const queryCollectionGallery = gql`
  query CollectionGallery(
    $categoryId: String!
    $filter: String
    $start: Int = 0
    $count: Int
    $currencyCode: String
    $countryCode: String
    $userType: String
    $siteId: String
    $measureSystem: String
    $locale: String
    $nextgenDriven: Boolean
  ) {
    collectionGallery(
      categoryId: $categoryId
      filter: $filter
      start: $start
      count: $count
      currencyCode: $currencyCode
      countryCode: $countryCode
      userType: $userType
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      nextgenDriven: $nextgenDriven
    ) {
      amountRemaining
      nextStart
      totalNumRecs
      bannerTitle
      bannerSubTitle
      collectionGallery {
        ...CollectionCategoryFields
      }
    }
  }
  ${CollectionCategoryFields}
`;
