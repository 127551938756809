import gql from "graphql-tag";
import { GiftRegistry } from "graphql-client/fragments/gift-registry";
import { GiftRegistryPublicFragment } from "graphql-client/fragments/gift-registry-public";

export const queryGetGiftRegistry = gql`
  query GetGiftRegistry(
    $id: String!
    $email: String!
    $shouldEnhance: Boolean
  ) {
    getGiftRegistry(id: $id, email: $email, shouldEnhance: $shouldEnhance) {
      ...GiftRegistry
    }
  }
  ${GiftRegistry}
`;

export const queryRegistryById = gql`
  query registryById(
    $id: String!
    $email: String!
    $currencyCode: String
    $registryAddresses: Boolean!
  ) {
    registryById(
      id: $id
      email: $email
      currencyCode: $currencyCode
      registryAddresses: $registryAddresses
    ) {
      ...GiftRegistry
    }
  }
  ${GiftRegistry}
`;

export const queryRegistryPublicById = gql`
  query RegistryPublicById(
    $id: String!
    $email: String!
    $currencyCode: String
    $registryAddresses: Boolean!
  ) {
    registryPublicById(
      id: $id
      email: $email
      currencyCode: $currencyCode
      registryAddresses: $registryAddresses
    ) {
      ...GiftRegistryPublicFragment
    }
  }
  ${GiftRegistryPublicFragment}
`;

export const mutationAddToRegsitry = gql`
  mutation addItemToRegistry(
    $items: [RegistryAddItemInput!]!
    $registryId: String!
    $email: String!
  ) {
    addItemToRegistry(items: $items, registryId: $registryId, email: $email) {
      ...GiftRegistry
    }
  }
  ${GiftRegistry}
`;

export const mutationAddMonogramToLineitem = gql`
  mutation addMonogramToLineitem(
    $itemId: String!
    $registryId: String!
    $monogramItem: MonogramInput!
    $email: String!
  ) {
    addMonogramToLineitem(
      itemId: $itemId
      registryId: $registryId
      monogramItem: $monogramItem
      email: $email
    ) {
      ...GiftRegistry
    }
  }
  ${GiftRegistry}
`;

export const mutationDeleteMonogramToLineitem = gql`
  mutation removeMonogramToLineitem(
    $itemId: String!
    $registryId: String!
    $email: String!
  ) {
    removeMonogramToLineitem(
      itemId: $itemId
      registryId: $registryId
      email: $email
    ) {
      ...GiftRegistry
    }
  }
  ${GiftRegistry}
`;
