import gql from "graphql-tag";
import { ProductConfiguration } from "./product";
// IMPORTANT: keep this query in its own file for optimal code chunking.
export const ProductAddons = gql`
  fragment ProductAddons on ProductAddons {
    productAddonsInfo {
      id
      customProduct
      imageUrl
      productAddonTitle
      productAddonMessage
      productAddonDescription
      onSale
      longDescription
      displayName
      isActive
      saleInfo {
        percentSaleSkus
        showSaleMessage
        memberSavings {
          memberSavingsMin
          memberSavingsMax
        }
      }
      priceRangeDisplay {
        rangeType
        isUnavailable
        isSale
        showMemberPrice
        skulowestListPrice
        skulowestMemberPrice
        skulowestSalePrice
        nextGenDriven
        nextGenDrivenOnSale
        listPrices
        salePrices
        memberPrices
        salePriceLabel
        priceMessage
        priceMessagePrice
        listPriceLabel
        memberPriceLabel
        strikePriceLabel
        currencySymbol
        hasOnlyOneSku
        __typename
      }
      colorizeInfo {
        colorizable
        __typename
      }
      alternateImages {
        imageUrl
        caption
        video
        sbrId
        __typename
      }
      productLineItem {
        availableOptions {
          type
          sortPriority
          optionTypeId
          options {
            id
            type
            value
            sortPriority
            status
            message
            __typename
          }
          __typename
        }
        image {
          productId
          imageUrl
          __typename
        }
        __typename
      }
      imageUrl
      __typename
      productConfiguration {
        __typename
        ...ProductConfiguration
      }
    }
    __typename
  }
  ${ProductConfiguration}
`;
