import gql from "graphql-tag";
import {
  ProductFields,
  ProductFieldsV2
} from "graphql-client-product/fragments/product-fields";

export const queryProduct = gql`
  query Product(
    $productId: String!
    $fullSkuId: String
    $swatchID: String
    $categoryId: String
    $filter: String
    $userType: String
    $currencyCode: String
    $siteId: String
    $measureSystem: String
    $locale: String
    $postalCode: String
  ) {
    product(
      productId: $productId
      categoryId: $categoryId
      fullSkuId: $fullSkuId
      swatchID: $swatchID
      filter: $filter
      userType: $userType
      currencyCode: $currencyCode
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      postalCode: $postalCode
    ) {
      __typename
      ...ProductFields
    }
  }
  ${ProductFields}
`;

export const queryProductV2 = gql`
  query Product(
    $productId: String!
    $fullSkuId: String
    $swatchID: String
    $categoryId: String
    $filter: String
    $userType: String
    $currencyCode: String
    $siteId: String
    $measureSystem: String
    $locale: String
    $postalCode: String
  ) {
    product(
      productId: $productId
      categoryId: $categoryId
      fullSkuId: $fullSkuId
      swatchID: $swatchID
      filter: $filter
      userType: $userType
      currencyCode: $currencyCode
      siteId: $siteId
      measureSystem: $measureSystem
      locale: $locale
      postalCode: $postalCode
    ) {
      __typename
      ...ProductFieldsV2
    }
  }
  ${ProductFieldsV2}
`;
