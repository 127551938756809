import gql from "graphql-tag";

export const querySurchargeInfo = gql`
  query SurchargeInfo($locale: String) {
    contentFragment(
      group: "general-modals"
      key: "surcharge-info"
      locale: $locale
    ) {
      text
    }
  }
`;
