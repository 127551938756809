import gql from "graphql-tag";

export const mutationAddItemsToGiftRegistry = gql`
  mutation AddItemsToGiftRegistry(
    $items: [GiftRegistryAddItemInput!]!
    $giftListId: String!
    $sessionConfirmation: String!
  ) {
    addItemsToGiftRegistry(
      items: $items
      giftListId: $giftListId
      sessionConfirmation: $sessionConfirmation
    ) {
      statusCode
    }
  }
`;

export const queryGetGiftRegistryLists = gql`
  query GetGiftRegistryList {
    giftRegistryLists {
      giftLists {
        giftListId
        giftListType
        eventDate
      }
    }
  }
`;

export const sendGiftRegistryAnnouncementEmail = gql`
  mutation sendGiftRegistryAnnouncementEmail(
    $userEmail: String!
    $emails: [String!]!
    $registryId: String!
    $messageBody: String!
  ) {
    sendGiftRegistryAnnouncementEmail(
      userEmail: $userEmail
      emails: { items: $emails }
      registryId: $registryId
      messageBody: $messageBody
    ) {
      id
    }
  }
`;
