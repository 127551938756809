import gql from "graphql-tag";

export const queryCategoryImage = gql`
  query CategoryImage(
    $categoryId: String!
    $swatchIds: String!
    $locale: String
    $countryCode: String
  ) {
    categoryImage(
      categoryId: $categoryId
      countryCode: $countryCode
      swatchIds: $swatchIds
      locale: $locale
    ) {
      displayName
      imageurl
      category
    }
  }
`;
