import gql from "graphql-tag";

export const GiftRegistryLineItemFragment = gql`
  fragment GiftRegistryLineItemFragment on LineItemRegistryType {
    sku
    type
    quantity
    quantityToPurchase
    brand
    lineId
    productId
    personalizeInfo {
      feeHigh
      feeLow
      selectedFont {
        id
        displayName
        previewImage
        image
        borders {
          id
        }
      }
      selectedBorder {
        id
        image
        displayName
        minLength
        maxLength
        numberOfLines
      }
      selectedStyle {
        id
        image
        minLength
        maxLength
        numberOfLines
        displayName
        previewImage
      }
      selectedColor {
        id
        displayName
        image
      }
    }
    isMonogramFee
    shipVia
    shipViaCode
    isMonogrammable
    isPersonalizable
    monogram {
      styleId
      description
      maxLines
      fontCode
      fontColorCode
      borderCode
      lines
      fulfillmentCode
    }
    price {
      itemPrice
      memberPrice
      originalMemberPrice
      salePrice
      isOnClearance
      isOnSale
      showMemberPrice
      itemShippingSurcharge
      fees
      priceType
      originalPrice
      currencyCode
      salePriceLabel
    }
    skuCustomization {
      type
      mountType
      lining
      panel
      controlType
      controlPosition
      controlLength
      tiltType
      controlAndTilt
      trim
      width
      length
      diameter
      roomLabel
      bracketColor
      rollType
    }
    product {
      targetUrl
      imageUrl
      displayName
    }
    registryQuantityPurchased
    stillNeeds
    options {
      id
      type
      value
      sortPriority
    }
    registryId
    displayName
    atgCustomOptions {
      mountTypeId
      controlTypeId
      controlPositionId
      liningId
      panelId
    }
  }
`;
