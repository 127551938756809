import gql from "graphql-tag";
import {
  Sku,
  SkuRestrictions,
  SkuInventory
} from "graphql-client/fragments/product-sku";

export const LineItem = gql`
  fragment LineItem on ProductLineItem {
    __typename
    sku {
      ...Sku
    }
    availableOptions {
      type
      sortPriority
      optionTypeId
      adapter
      options {
        id
        type
        value
        name
        sortPriority
        status
        message
        usa_list_price_f
        usa_member_price_f
        usa_sale_price_f
        can_list_price_f
        can_member_price_f
        can_sale_price_f
        gbr_list_price_f
        gbr_member_price_f
        gbr_sale_price_f
      }
    }
    image {
      productId
      imageUrl
    }
    customProductOptions {
      customProductInformation {
        customProductType
        cwCustomProductCode
        maxWidth
        maxLength
        minWidth
        minLength
        minDiameter
        maxDiameter
        maxControlLength
        minControlLength
        widthFractionGap
        lengthFractionGap
        diameterFractionGap
        shape
        showSizeDropDown
        type
        mountTypes {
          id
          value
          status
          message
          code
        }
        controlTypes {
          id
          value
          status
          message
          continuousLoop
          motorized
        }
        panels {
          id
          value
          status
          message
          code
        }
        linings {
          id
          value
          status
          message
        }
        controlPositions {
          id
          value
          status
          message
        }
        rollTypes {
          id
          value
          status
          message
        }
        bracketColors {
          id
          value
          status
          message
        }
        controlsAndTilts {
          id
          value
          status
          message
        }
        tiltTypes {
          id
          value
          status
          message
          continuousLoop
          motorized
        }
        rugTrims {
          id
          value
          status
          message
          code
        }
        finish {
          id
          value
          status
          message
        }
      }
    }
  }
  ${Sku}
`;

export const LineItemLite = gql`
  fragment LineItemLite on ProductLineItem {
    __typename
    availableOptions {
      type
      sortPriority
      optionTypeId
      adapter
      options {
        id
        type
        value
        name
        sortPriority
        status
        message
        usa_list_price_f
        usa_member_price_f
        usa_sale_price_f
        can_list_price_f
        can_member_price_f
        can_sale_price_f
        gbr_list_price_f
        gbr_member_price_f
        gbr_sale_price_f
      }
    }
    customProductOptions {
      customProductInformation {
        customProductType
        cwCustomProductCode
        maxWidth
        maxLength
        minWidth
        minLength
        minDiameter
        maxDiameter
        maxControlLength
        minControlLength
        widthFractionGap
        lengthFractionGap
        diameterFractionGap
        shape
        showSizeDropDown
        type
        mountTypes {
          id
          value
          status
          message
          code
        }
        controlTypes {
          id
          value
          status
          message
          continuousLoop
          motorized
        }
        panels {
          id
          value
          status
          message
          code
        }
        linings {
          id
          value
          status
          message
        }
        controlPositions {
          id
          value
          status
          message
        }
        rollTypes {
          id
          value
          status
          message
        }
        bracketColors {
          id
          value
          status
          message
        }
        controlsAndTilts {
          id
          value
          status
          message
        }
        tiltTypes {
          id
          value
          status
          message
          continuousLoop
          motorized
        }
        rugTrims {
          id
          value
          status
          message
          code
        }
        finish {
          id
          value
          status
          message
        }
      }
    }
  }
`;

export const LineItemSku = gql`
  fragment LineItemSku on ProductLineItem {
    __typename
    sku {
      ...SkuRestrictions
    }
  }
  ${SkuRestrictions}
`;

export const LineItemInventory = gql`
  fragment LineItemInventory on ProductLineItem {
    __typename
    sku {
      ...SkuInventory
    }
  }
  ${SkuInventory}
`;

export const LineItemSkuDetails = gql`
  fragment LineItemSkuDetails on ProductSku {
    ...Sku
  }
  ${Sku}
`;
