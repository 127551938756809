import gql from "graphql-tag";
import { PromoDisplayFields } from "graphql-client/fragments/promo-display";
import { SaleMemberInfoFields } from "graphql-client/fragments/pg-sale-member-info";

export const CollectionCategoryFields = gql`
  fragment CollectionCategoryFields on CollectionCategory {
    __typename
    id
    type
    displayName
    collection
    colorizable
    colorizeType
    colorizationFileName
    altImages {
      imageId
      imageUrl
      caption
      autoplay
      lifestyleImage
    }
    hasProducts
    subTemplate
    onSale
    introText
    cgBannerImage
    cgBannerTemplate
    showSwatches
    pgCrop
    pgCropRules
    uxAttributes {
      showSpecial
      forceSaleFlag
      forceTextileSaleFlag
    }
    priceInfo {
      priceCopy
      currencySymbol
      lowestPriceSku
      listPrice
      memberPrice
      tradePrice
      contractPrice
      salePrice
      onClearance
      memberOriginalPrice
    }
    bannerCopy
    bannerTitle
    bannerSubTitle
    bannerSubtitle3
    bannerSubSubTitle
    bannerSubtitle3
    bannerSwatchImage
    dynamicSwatches {
      totalNumSwatchIds
      saleSwatchMatch
      displaySaleCopy
      moreNumSwatchIds
      allSwatchIdsOnSale
      displaySwatch {
        id
        sale
      }
      top5SwatchIds {
        id
        sale
        imageUrl
        displayName
        imageRef
      }
    }
    promotionDisplayItems {
      addbanner {
        ...PromoDisplayFields
      }
      custombanner {
        ...PromoDisplayFields
      }
      eventbanner {
        ...PromoDisplayFields
      }
      salesectionbanner {
        ...PromoDisplayFields
      }
    }
    saleBannerCopy {
      text
    }
    forceSaleFlag
    saleMemberInfo {
      ...SaleMemberInfoFields
    }
    hasShellProducts
    stockInfo {
      totalSkus
      inStock
      inStockQty
    }
    stockedDeliveryMessage
    specialOrderDeliveryMessage
    additionalBannerCopy
    keywords
    seoDescription
    seoPageTitle
    saleMessageEnabled
    onCategoryFinishSale
    useBannerLink
    bannerLink
  }
  ${PromoDisplayFields}
  ${SaleMemberInfoFields}
`;
