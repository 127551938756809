import gql from "graphql-tag";

export const MonogramBaseFields = gql`
  fragment MonogramBaseFields on MonogramBaseFields {
    __typename
    id
    altImageUrl
    displayName
    imageUrl
    maxLength
    minLength
    numberOfLines
    cwCode
  }
`;
