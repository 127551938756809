import gql from "graphql-tag";

export const mutationSubscribeToBrands = gql`
  mutation SubscribeToBrands(
    $email: String!
    $brands: [String!]!
    $country: String!
    $locale: String!
  ) {
    subscribeToBrands(
      email: $email
      brands: $brands
      country: $country
      locale: $locale
    ) {
      RH
      BC
      TN
      MO
      OUTLET
    }
  }
`;

export const queryGetBrandSubscriptionsByEmail = gql`
  query GetBrandSubscriptionsByEmail($email: String!, $country: String) {
    getBrandSubscriptionsByEmail(email: $email, country: $country) {
      RH
      BC
      TN
      MO
      OUTLET
    }
  }
`;

export const mutationUnsubscribeToBrand = gql`
  mutation UnSubscribeToBrands(
    $email: String!
    $brand: String!
    $country: String
  ) {
    unsubscribeToBrand(email: $email, brand: $brand, country: $country) {
      RH
      BC
      TN
      MO
      OUTLET
    }
  }
`;

export const mutationUnsubscribeToBrands = gql`
  mutation UnSubscribeToBrands(
    $email: String!
    $brands: [String!]!
    $country: String
  ) {
    unsubscribeToBrands(email: $email, brands: $brands, country: $country) {
      RH
      BC
      TN
      MO
      OUTLET
    }
  }
`;
