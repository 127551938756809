import gql from "graphql-tag";
import { MonogramBaseFields } from "graphql-client/fragments/monogram-base-fields";

export const getMonogramOptions = gql`
  query monogramOptions($email: String!, $productId: String!) {
    monogramOptions(email: $email, productId: $productId) {
      productId
      fonts {
        id
        altImageUrl
        displayName
        imageUrl
        maxLength
        minLength
        numberOfLines
        cwCode
        borders {
          style {
            ...MonogramBaseFields
          }
        }
      }
      styles {
        ...MonogramBaseFields
      }
      colors {
        ...MonogramBaseFields
      }
    }
  }
  ${MonogramBaseFields}
`;
