import gql from "graphql-tag";

export const queryReplacementParts = gql`
  query ReplacementParts(
    $productId: String!
    $fullSkuId: String!
    $postalCode: String!
    $country: String
    $filter: String = ""
    $siteId: String
  ) {
    replacementParts(
      productId: $productId
      fullSkuId: $fullSkuId
      postalCode: $postalCode
      country: $country
      filter: $filter
      siteId: $siteId
    ) {
      fullSkuId
      productId
      instockReplacementParts {
        fullSkuId
        productId
        itemDescription
        inStockLowQtyMessage
        inventoryMessage
        qtyAvailable
        optionList {
          id
          label
          optionType
          sortPriority
        }
        skuPriceInfo {
          currencySymbol
          listPrice
          salePrice
          memberPrice
          tradePrice
          contractPrice
          onSale
          onClearance
          showMemberPrice
        }
      }
    }
  }
`;
