import gql from "graphql-tag";
import { GiftRegistryLineItemFragment } from "./gift-registry-line-item";

export const GiftRegistryPublicFragment = gql`
  fragment GiftRegistryPublicFragment on GiftRegistryPublic {
    __typename
    id
    brand
    accessLevel
    acceptGiftCard
    event
    eventDateTime

    lineItems {
      ...GiftRegistryLineItemFragment
    }

    isAuthor

    postEventAddressSelection
    preEventAddressSelection

    registrantAddress {
      firstName
      lastName
      city
      state
    }
    coRegistrantAddress {
      firstName
      lastName
      city
      state
    }
    preEventAddress {
      firstName
      lastName
      city
      state
    }
    postEventAddress {
      firstName
      lastName
      city
      state
    }
  }
  ${GiftRegistryLineItemFragment}
`;
