import gql from "graphql-tag";

export const queryProductSwatchImage = gql`
  query ProductSwatchImage(
    $productId: String!
    $optionIds: [String!]
    $siteId: String
    $locale: String
    $trimOptionIds: [String!]
    $fullskuId: String
  ) {
    product(
      productId: $productId
      categoryId: null
      filter: null
      userType: null
      siteId: $siteId
      currencyCode: null
      measureSystem: null
      locale: $locale
    ) {
      productSwatchImage(
        optionIds: $optionIds
        siteId: $siteId
        locale: $locale
        trimOptionIds: $trimOptionIds
        fullskuId: $fullskuId
      ) {
        imageUrl
      }
    }
  }
`;
