import gql from "graphql-tag";

export const querySkuAvailabilityStatusBulk = gql`
  query skuAvailabilityStatusBulk(
    $country: String
    $postalCode: String
    $skus: [SkuInput!]!
  ) {
    skuAvailabilityStatusBulk(
      country: $country
      postalCode: $postalCode
      skus: $skus
    ) {
      country
      skuStatus {
        sku
        quantityRequested
        availabilityStatus
        maxQuantity
        inStockDisplayQuantity
      }
    }
  }
`;
