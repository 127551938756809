import gql from "graphql-tag";
// IMPORTANT: keep this query in its own file for optimal code chunking.
export const PriceRangeDisplay = gql`
  fragment PriceRangeDisplay on ProductPriceRangeDisplay {
    __typename
    isUnavailable
    showMemberPrice
    skulowestMemberPrice
    nextGenDriven
    nextGenDrivenOnSale
    listPrices
    memberPrices
    currencySymbol
    overridePriceLabel
  }
`;
