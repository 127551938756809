import gql from "graphql-tag";
import { PriceRangeDisplay } from "graphql-client-product/fragments/price-range-display";
import { ProductAddons } from "./product-addons-fields";

export const BaseProductFields = gql`
  fragment BaseProductFields on Product {
    newPdpLayout
    collectionName
    collectionCategoryId
    autoplay
    preconfiguredSku
    imagePresetOverride
    isActive
    videoUrl
    endDate
    subName
    metaProduct
    longDescription
    merchMessage
    targetUrl # Can't tell, might be unused
    emptyProduct
    metaProductComponent
    onSale
    giftCert # Unused
    featureList
    dimensions
    deliveryDimensions
    careInstructions
    fixedDisplaySku # Unused
    layout
    productListTitle
    id
    type
    displayName
    imageUrl
    galleryDescription
    newProduct
    template
    suppressSwatchCopy
    alternateImages {
      imageUrl
      caption
      video
      sbrId
      autoplay
      lifestyleImage
      sliderCss
    }
    productAddonDescription
    productAddonMessage
    productAddonTitle
    productAddonDisplayOptions
    productAddons {
      __typename
      ...ProductAddons
    }
    colorizeInfo {
      colorizable
    }
    layout
    priceRangeDisplay {
      ...PriceRangeDisplay
    }
    priceRangeMultiSkuDisplay {
      ...PriceRangeDisplay
    }
    index # Unused, I think? Hard to search for
    rangeId # Unused
    parentCategoryId
    fileLinkUrls {
      link
      label
    }
    instock {
      hasInStock
      showInStockButton
      showInStockMessage
    }
    saleInfo {
      percentSaleSkus
      showSaleMessage
      memberSavings {
        memberSavingsMin
        memberSavingsMax
      }
    }
    # skuPriceInfo {
    #   ...SkuSalePrice
    # }
    # multiSkuPriceInfo {
    #   ...SkuSalePrice
    # }
    sale {
      hasSale
      showSaleButton
      showSaleMessage
    }
    uxAttributes {
      productType
      triggerSwatchImage
      giftCert
      membershipProduct
    }
    swatchesToBuy {
      atgSkuId
      swatchId
      productId
      fullSkuId
    }
    customProduct
    rhr
    showAllOptions
    hideOptionSet
    pdpImageLayout
    pdpCrop
    serviceSkuProduct
    nextGenDriven
    multiSkuAdditionalMessage
    seoDescription
    keywords
    relatedProductIds
    saleSwatchId
    productMessage
    swatch
    category
  }
  ${PriceRangeDisplay}
  ${ProductAddons}
`;
