import gql from "graphql-tag";

export const queryInStockItems = gql`
  query InstockItems(
    $productId: String!
    $postalCode: String
    $filter: String = ""
    $userType: String
    $currencyCode: String
    $siteId: String!
    $locale: String!
    $fromCount: String
    $toCount: String
    $nextgenDriven: Boolean
    $sortBy: String
    $page: String
    $resultsPerPage: String
  ) {
    instockItems(
      productId: $productId
      postalCode: $postalCode
      filter: $filter
      userType: $userType
      currencyCode: $currencyCode
      siteId: $siteId
      locale: $locale
      fromCount: $fromCount
      toCount: $toCount
      nextgenDriven: $nextgenDriven
      sortBy: $sortBy
      page: $page
      resultsPerPage: $resultsPerPage
    ) {
      showViewMore
      adapter
      instockItems {
        skuId
        fullSkuId
        inStockLowQtyMessage
        inventoryMessage # FIXME: Unused
        imageUrl
        qtyAvailable
        inventoryOnHand
        skuIds
        components {
          fullSkuId
          productId
          quantity
        }
        bundleComponents {
          imageUrl
          productId
          fullSkuId
          name
        }
        optionList {
          id
          label
          optionType
          sortPriority # Unused - can probably remove this
        }
        skuPriceInfo {
          fullSkuId # Unused - presumably because shows up multiple times
          currencySymbol
          listPrice
          salePrice
          memberPrice
          onSale
          onClearance # FIXME: Unused
          showMemberPrice
          contractPrice
          tradePrice
        }
      }
    }
  }
`;
