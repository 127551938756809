import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { initialAppData, queryApp } from "./queries/app";
// import introspectionQueryResultData from "./introspection-result.json";
import { keycloak } from "utils-keycloak/KeyCloak";
import { getAppData } from "hooks/useAppData";
import { useEnv } from "hooks/useEnv";
import analyticsLoader from "analytics/loader";
import { safeIsInAemEditor } from "utils/safeIsInAemEditor";
import DebounceLink from "omerman-apollo-link-debounce";
import { v4 as uuidv4 } from "uuid";
import type { ClientType } from "./client-type";
import customFetch from "utils/customFetch";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { uncrunch, CrunchedData } from "graphql-crunch";
import yn from "yn";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import getSiteId from "utils/getSiteId";

declare global {
  interface Window {
    __APOLLO_STATE__: any;
  }
}
let init;

const clientIndex = (): ClientType => {
  init = factory();
  return init;
};

const bufferToHex = buffer => {
  const view = new DataView(buffer);
  let hexCodes = "";
  for (let index = 0; index < view.byteLength; index += 4) {
    hexCodes += view.getUint32(index).toString(16).padStart(8, "0");
  }
  return hexCodes;
};

const sha256 = async (buffer: any) => {
  buffer = new globalThis.TextEncoder().encode(buffer);
  const hash = await globalThis.crypto.subtle.digest("SHA-256", buffer);
  return bufferToHex(hash);
};

function load(num: number, exportObject, activeRequests: number) {
  setTimeout(async () => {
    const { app, setApp } = await getAppData(exportObject.client);
    let loading = activeRequests + num;
    activeRequests = loading < 0 ? 0 : loading;
    if (!app.isLoading && activeRequests > 0) {
      setApp({
        ...app,
        isLoading: true
      });
    } else if (app.isLoading && activeRequests < 1) {
      setApp({
        ...app,
        isLoading: false
      });
    }
  });
}

export const factory = (uri?) => {
  const exportObject: any = {};

  const env = useEnv();

  const FEATURE_GRAPHQL_CACHE = yn(env?.FEATURE_GRAPHQL_CACHE);
  const FEATURE_GRAPHQL_CRUNCH = yn(env?.FEATURE_GRAPHQL_CRUNCH);
  const FEATURE_GRAPHQL_PERSISTED_QUERIES = yn(
    env?.FEATURE_GRAPHQL_PERSISTED_QUERIES
  );

  let activeRequests: number = 0;

  const siteId = getSiteId();
  const countryCode = getCountryFromUrl();

  const cache = FEATURE_GRAPHQL_CACHE
    ? new InMemoryCache({
        possibleTypes: {
          SearchRefinementLink: [
            "SearchRefinementImageLink",
            "SearchRefinementPlainLink"
          ]
        },
        typePolicies: {
          // Fixes KRAK-2827 duplicate product options
          ProductItemAttribute: {
            // if id is null this prevents the cache from overwriting if we ensure that the keyField is unique somehow
            keyFields: o =>
              `ProductItemAttribute:${o.__typename}:${
                o.id
              }:${`${o.value}`.replace(/ /g, "")}:${siteId}:${countryCode}`
          },
          LineItemType: {
            keyFields: o => `LineItemType:${o.lineId}:${siteId}:${countryCode}`
          },
          MonogramType: {
            keyFields: o => `MonogramType:${o.styleId}:${siteId}:${countryCode}`
          },
          // Fixes SHOP-1110
          NavigationElement: {
            keyFields: o =>
              `NavigationElement:${o.__typename}:${o.id}:${o.displayName}:${siteId}:${countryCode}`
          },
          SaleNavigationElement: {
            keyFields: o =>
              `SaleNavigationElement:${o.__typename}:${o.id}:${o.displayName}:${siteId}:${countryCode}:${o?.filter}`
          },
          ProductItemOption: {
            keyFields: o =>
              `ProductItemOption:${o.__typename}:${o.id}:${o?.optionType}:${o?.label}:${siteId}:${countryCode}`?.replace(
                /\s/gi,
                ""
              )
          },
          ProductFullSkuSwatchIdsArgs: {
            keyFields: o =>
              `ProductFullSkuSwatchIdsArgs:${o?.fullSkuId}:${siteId}:${countryCode}`
          },
          ProductSwatch: {
            keyFields: o =>
              `ProductSwatch:${o?.__typename}:${o?.swatchGroupName}:${o?.swatchId}:${siteId}:${countryCode}:`
          },
          Product: {
            keyFields: o => `Product:${o?.id}:${siteId}:${countryCode}`
          },
          CategoryProduct: {
            keyFields: o =>
              `CategoryProduct:${o?.id}:${o?.displayName}:${siteId}:${countryCode}`
          },
          // Q: How is searchQueryId generated? and is it unique?
          SearchResponse: {
            keyFields: o =>
              `SearchResponse:${o?.searchQueryId}:${siteId}:${countryCode}`
          },
          QueryInfiniteScrollArgs: {
            keyFields: o =>
              `QueryInfiniteScrollArgs:${o?.product_id}:${o?.categoryId}:${siteId}:${countryCode}`
          },
          TopSwatch: {
            keyFields: o =>
              `TopSwatch:${o?.id}:${o?.sale}:${o?.imageRef}:${siteId}:${countryCode}`
          },
          // Doesn't have a displayName
          // Is part of the larger SearchResponse object
          // Do we need a sepeate keyFields for this?
          // {
          //   "resultList": {
          //     "lastRecNum": 35,
          //     "firstRecNum": 24,
          //     "recsPerPage": 12,
          //     "totalNumRecs": 1378,
          //     "records": [],
          //     "sortOptions": [],
          //     "__typename": "SearchResultList"
          //   }
          // }
          // SearchResultList: {
          //   keyFields: o => `SearchResultList:${o?.displayName}`
          // },
          // Doesn't seem to have a display name
          // {
          //  "__typename": "SearchResultRecord",
          // 	"recordType": null,
          // 	"product": {
          // 		"__typename": "SearchRecordProduct"
          //    ...
          // 	},
          // 	"sku": {
          // 		"__typename": "SearchRecordSku",
          // 		"fullSkuId": "10177789 BRN"
          // 	}
          // }
          // Results in the error below:
          // Cache data may be lost when replacing the records field of a SearchResultList object.
          // To address this problem (which is not a bug in Apollo Client), define a custom merge
          // function for the SearchResultList.records field, so InMemoryCache can safely merge these objects:
          // SearchResultRecord: {
          //   keyFields: o => {
          //     return `SearchResultRecord:${`${o?.displayName}`?.replace(
          //       /\s/g,
          //       ""
          //     )}`;
          //   }
          // },
          // Used for search graphql queries (Used in Search, PGs pagination)
          // SearchResponse -> SearchResultList -> SearchResultRecord -> SearchRecordProduct
          SearchRecordProduct: {
            keyFields: o => {
              const skuId = (o?.skuPriceInfo as any)?.fullSkuId?.replace(
                /\s/g,
                ""
              );
              const suffix = skuId ? `:${skuId}` : "";
              const displayName = (o?.displayName as string)?.replace(
                /\s/g,
                ""
              );
              const key = `SearchRecordProduct:${displayName}:${o?.repositoryId}${suffix}:${siteId}:${countryCode}`;
              return key;
            }
          }
          // Currently not getting cached due to lack of a unique id
          // I don't see any unique ids in the response
          // Currently called with fetchPolicy: "network-only" so it doesn't matter
          // ProductLineItem: {
          //   keyFields: o => {
          //     return `ProductLineItem:${o?.image?.['productId']}}`
          //   }
          // }
        }
        // TODO: determine why this is breaking CG and PG queries
        // addTypename: false,
        // possibleTypes: introspectionQueryResultData.possibleTypes
      })
    : new InMemoryCache({ dataIdFromObject: () => null });

  let cacheOptions: any = { cache };
  if (!FEATURE_GRAPHQL_CACHE) {
    cacheOptions = {
      ...cacheOptions,
      defaultOptions: {
        query: {
          errorPolicy: "all",
          fetchPolicy: "no-cache"
        },
        watchQuery: {
          errorPolicy: "all",
          fetchPolicy: "no-cache"
        }
      }
    };
  }

  if (FEATURE_GRAPHQL_CACHE) {
    cache.writeQuery({
      query: queryApp,
      data: initialAppData
    });
  }

  if (isSSRToggledWithClientRender()) {
    //only run on client side
    try {
      exportObject.cache = cache.restore(window.__APOLLO_STATE__);
    } catch (e) {
      // TODO: Need debug flag where all logging is enabled/disabled
      console.log("Failed to restore from cache.", e);
    } finally {
      const data = document.querySelector("#apollo-data");
      if (data) {
        data.parentElement?.removeChild(data);
      }
    }
  } else {
    exportObject.cache = cache;
  }

  const httpLink = new HttpLink({
    uri: `${uri ? uri : env.REACT_APP_BFF_ORIGIN}${
      env.REACT_APP_BFF_HTTP_LINK_PATH
    }`,
    credentials: "include",
    fetchOptions: {
      method: "GET"
    },
    fetch: customFetch
  });

  const mergedLink =
    FEATURE_GRAPHQL_PERSISTED_QUERIES &&
    globalThis.crypto &&
    globalThis.crypto.subtle
      ? createPersistedQueryLink({ sha256 }).concat(httpLink)
      : httpLink;

  exportObject.client = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([
      new DebounceLink(100),
      new ApolloLink((operation, forward) => {
        return forward(operation).map(response => {
          if (FEATURE_GRAPHQL_CRUNCH && response && response.data) {
            response.data = uncrunch(response.data as CrunchedData) as {
              [key: string]: any;
            };
          }
          return response;
        });
      }),
      new ApolloLink((operation, forward) => {
        const newHeaders: { [key: string]: string } = {
          authorization: keycloak.token ? `Bearer ${keycloak.token}` : "",
          "x-operation": operation?.operationName
        };

        if (sessionStorage.getItem("perf_profile")) {
          newHeaders["x-bff-perf-profile"] =
            sessionStorage.getItem("perf_profile")!;
        }

        if (sessionStorage.getItem("perf_profile_explain")) {
          newHeaders["x-bff-perf-profile-explain"] = sessionStorage.getItem(
            "perf_profile_explain"
          )!;
        }

        newHeaders["x-request-id"] = uuidv4().replace(/-/g, "");
        newHeaders["x-client-locale"] = getLanguageFromUrl().mapped;
        newHeaders["x-client-country"] = getCountryFromUrl();

        operation.setContext(({ headers: oldHeaders }) => {
          return { headers: { ...oldHeaders, ...newHeaders } };
        });

        load(+1, exportObject, activeRequests);
        const forwardedOp = forward(operation).map(data => {
          // ...modify result as desired here...
          load(-1, exportObject, activeRequests);
          return data;
        });
        return forwardedOp;
      }),
      onError(error => {
        const { graphQLErrors, networkError } = error;
        const notify = async (message: string) => {
          const { app, setApp } = await getAppData(exportObject.client);
          setApp({
            ...app,
            message: {
              ...app.message,
              text: message,
              variant: "error",
              isHidden: true
            }
          });
        };

        if (networkError) {
          const serverError = networkError as ServerError;
          if (serverError.result && serverError.result.errors) {
            (serverError.result.errors as Error[]).forEach(error => {
              notify(error.message);
            });
          } else {
            notify(networkError.message || networkError.name);
          }
        }

        (graphQLErrors || []).forEach(error => {
          if (error.path) {
            if (
              error.path.includes("sessionConf") &&
              window.location.pathname !== "/"
            ) {
              analyticsLoader(a =>
                a.emitAnalyticsEvent(
                  // @ts-ignore
                  document.querySelector("#spa-root > *")!,
                  a.EVENTS.ADD_TO_LOCALSTORAGE.INT_TYPE,
                  { remove: "all" }
                )
              );

              if (!safeIsInAemEditor()) {
                window.location.replace("/");
              }
              return;
            }
          }

          if (error.extensions && error.extensions.exception) {
            if (error.extensions.exception.statusCode === 404) {
              setTimeout(() => {
                window.location.replace("/error/404-error.html");
                return;
              });
            }
            if (error.extensions.exception.statusCode === 403) {
              window.location.replace("/error/403-error.html");
              return;
            } else if (error.extensions.exception.statusCode === 500) {
              window.location.replace("/error/500-error.html");
              return;
            }
          }
        });
      }),
      mergedLink
    ]),
    name: "rh-estore-client",
    ssrForceFetchDelay: 100,
    ...cacheOptions
  });

  return exportObject as ClientType;
};
export default init ? () => init : clientIndex;
