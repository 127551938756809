import gql from "graphql-tag";
import { GiftRegistryLineItemFragment } from "./gift-registry-line-item";

export const GiftRegistry = gql`
  fragment GiftRegistry on GiftRegistryType {
    __typename
    id
    accessLevel
    acceptGiftCard
    brand
    acceptGiftCard
    country
    event
    eventDateTime
    eventDesc
    registryNumber
    referenceId
    gallery {
      designerCart
      galleryId
      address {
        addressLine1
        addressLine2
        city
        country
        email
        firstName
        lastName
        phone
        eveningPhone
        postalCode
        state
      }
      orderClassification
      key
      ctId
    }
    purchaseLines {
      registryLineId
      cartLineId
      orderId
      displayName
      sku
      quantity
      thankYouNote
      fromFirst
      fromLast
      datePurchased
      lineItemSlug
      productId
      imageUrl
      targetUrl
      brand
    }
    lineItems {
      ...GiftRegistryLineItemFragment
    }
    postEventAddressSelection
    preEventAddressSelection
    guest {
      userId
      ctId
      atgUserId
      cwUserId
      email
      type
      typeId
      sourceCode
    }
    registrantAddress {
      addressLine1
      addressLine2
      city
      country
      email
      firstName
      lastName
      phone
      eveningPhone
      postalCode
      state
    }
    coRegistrantAddress {
      addressLine1
      addressLine2
      city
      country
      email
      firstName
      lastName
      phone
      eveningPhone
      postalCode
      state
    }
    preEventAddress {
      addressLine1
      addressLine2
      city
      country
      email
      firstName
      lastName
      phone
      eveningPhone
      postalCode
      state
    }
    postEventAddress {
      addressLine1
      addressLine2
      city
      country
      email
      firstName
      lastName
      phone
      eveningPhone
      postalCode
      state
    }
  }
  ${GiftRegistryLineItemFragment}
`;
