import gql from "graphql-tag";

export const MembershipInfo = gql`
  fragment MembershipInfo on MembershipInfoType {
    membershipSkuIsOnCart
    userHasMembershipStatus
    userHasActiveMembership
    userHasCancelledMembership
    userHasExpiredMembership
    membershipStatusDescription
    membershipSkuPrice
    currencyCode
    membershipId
  }
`;
