import gql from "graphql-tag";

export const Swatch = gql`
  fragment Swatch on ProductSwatch {
    __typename
    swatchId
    title
    sortPriority # Unused
    swatchGroupIndex # Unused
    swatchGroupName
    details # FIXME: Unused (seems important?)
    colorize # Unused
    imageUrl
    primaryOptionId # Unused
    secondaryOptionId # Unused
    featuredPallete
    relatedSwatchBeans {
      swatchId
      title
      sortPriority # Unused
      swatchGroupIndex # Unused
      details # FIXME: Unused (seems important?)
      colorize # Unused
      imageUrl
      primaryOptionId # Unused
      secondaryOptionId # Unused
      optionCopy
      options {
        id
        label
        optionType
        sortPriority
      }
      # FIXME: Unused
      swatchImageSm {
        url
        width
        height
      }
      swatchImageLg {
        url
        width
        height
      }
    }
    optionCopy
    options {
      id
      label
      optionType
      sortPriority
    }
    # FIXME: Unused
    swatchImageSm {
      url
      width
      height
    }
    swatchImageLg {
      url
      width
      height
    }
    onSale
  }
`;

export const SwatchGroup = gql`
  fragment SwatchGroup on ProductSwatchGroup {
    __typename
    swatchGroupName
    groupMaterial
    swatchGroupIndex # Unused
    sortPriority
    stockedSwatches {
      ...Swatch
    }
    customSwatches {
      ...Swatch
    }
  }
  ${Swatch}
`;

export const PersonalizeInfo = gql`
  fragment PersonalizeInfo on ProductPersonalizeInfo {
    __typename
    description
    features
    feeLow # Unused
    feeHigh # Unused
    monogrammable
    personalizable
    waiveMonogramFee
    # Unused
    styles {
      image
      previewImage
      displayName
      id
      minLength
      maxLength
      numberOfLines
    }
    # Unused
    fonts {
      image
      previewImage
      displayName
      id
      borders {
        image
        minLength
        maxLength
        numberOfLines
        id
        displayName
      }
    }
    # Unused
    colors {
      image
      id
      displayName
    }
  }
`;

export const ProductSwatchGroupV2 = gql`
  fragment ProductSwatchGroupV2 on ProductSwatchGroupV2 {
    swatchGroupName
    groupMaterial
    groupMaterialType
    typeId
    swatches {
      swatchId
      swatchGroupName
      typeId
      title
      imageUrl
      swatchImageSm {
        url
        width
        height
      }
      swatchImageLg {
        url
        width
        height
      }
      onSale
      swatchSkuIds {
        skuIds
        optionIds
        listPrice
        tradePrice
        contractPrice
        memberPrice
        displayPrice
        swatchProductId
      }
      combinedSwatchSkuIds {
        skuIds
        listPrice
        tradePrice
        contractPrice
        memberPrice
        displayPrice
        swatchProductIds
      }
      optionCopy
    }
  }
`;

export const ProductSwatchV2 = gql`
  fragment ProductSwatchV2 on ProductSwatchV2 {
    productId
    swatchGroups {
      __typename
      swatchGroupMaterial
      swatchGroupMaterialType
      swatchGroupMaterialTypeId
      swatchGroupList {
        ...ProductSwatchGroupV2
      }
    }
    finishSwatchGroups {
      __typename
      ...ProductSwatchGroupV2
    }
  }
  ${ProductSwatchGroupV2}
`;

export const ProductConfiguration = gql`
  fragment ProductConfiguration on ProductInfoV3 {
    productId
    optionMatrix
    instockOptionMatrix
    inStockStatus
    preselectedOptions
    default_preselected_options
    default_instock_preselected_options
    swatchData {
      __typename
      ...ProductSwatchV2
    }
    optionDetails {
      type
      name
      swatchGroupMaterialType
      options {
        id
        typeId
        value
        name
      }
    }
  }
  ${ProductSwatchV2}
`;
